import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

function TechnologyStack({ data, title }) {
  const responsive = window.innerWidth > 500;

  return (
    <>
      <div className="technolgy_heading">{title}</div>

      <div className="techlogt_itmes">
        {data.map((item, index) => (
          <div className="technolgy_box">
            <img src={item.img} width="100%" />
          </div>
        ))}
      </div>
    </>
  );
}

export default TechnologyStack;
