import "./App.css";

import AllRoute from "./navigation/AllRoute";

function App() {
  return (
    <div className="App">
      <AllRoute />
    </div>
  );
}

export default App;
