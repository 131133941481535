import { Button, Grid } from "@mui/material";
import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import BuildAppSubmit from "../Components/BuildAppSubmit";
import MetaData from "../Components/MetaData";
import NineCards from "../Components/NineCards";
import RequestButton2 from "../Components/RequestButton2";
import RequestButton from "../Components/RequestButton";
import NavBarSec from "../Components/NavBar2";

function ChatbotDevelopment() {
  const responsive = window.innerWidth > 500;
  return (
    <>
      <NavBarSec />
      <div style={{ textAlign: responsive ? "" : "center" }}>
        <MetaData title={"PLANNING TO INTEGRATE CHATBOTS IN YOUR BUSINESS?"} />

        <Grid
          md={12}
          style={{
            background: "#FFBE30",
            minHeight: 650,
            display: "flex",
            justifyContent: "center"
          }}
          container
        >
          <Grid item xs={12} md={6}>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: responsive ? "" : 10
              }}
            >
              <div style={{ color: "white", marginRight: 20 }}>
                <div
                  style={{
                    fontSize: responsive ? 40 : 25,
                    fontWeight: 700,
                    marginRight: responsive ? 0 : 10,
                    marginLeft: responsive ? 0 : 10
                  }}
                >
                  PLANNING TO INTEGRATE CHATBOTS IN YOUR BUSINESS?
                </div>

                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    marginTop: 10,
                    marginRight: responsive ? 0 : 10,
                    marginLeft: responsive ? 0 : 10
                  }}
                >
                  We can help you create a working MVP for your app idea within
                  your budgets and timelines.
                </div>
                <RequestButton />
              </div>
            </div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div style={{ marginTop: 70 }}>
              <img
                width="100%"
                height="100%"
                src={require("../assets/chatBot.png")}
              />
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            paddingTop: 50,
            paddingLeft: 10,
            paddingRight: 10
          }}
        >
          <div>
            <div
              style={{
                fontSize: 25,
                fontWeight: 630
              }}
            >
              Innovative
            </div>
            <div
              style={{
                fontSize: 25,
                fontWeight: 700
              }}
            >
              Chatbot Development
            </div>
            <div
              style={{
                marginTop: 30,
                fontWeight: 450,
                paddingRight: 10,
                paddingLeft: 10
              }}
            >
              <div>
                Chatbots are one of the essential products of AI technology that
                have garnered massive popularity in today’s corporate sphere.
                Getting 100% dedication to the operations of your business at
                all times is now possible with any additional directive or
                instruction. It is a cost-effective eliminating the worry about
                paying wages and lets human resources off the hook for providing
                a day-long service. If you wish for your ideas to be executed
                effectively and efficiently, hire Chabot developers from Bitsbay
              </div>
              <div
                style={{
                  marginTop: 30,
                  fontSize: 25,
                  fontWeight: 700
                }}
              >
                Bitsbay Weaving Its Magic
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: 30,
              fontWeight: 450,

              paddingRight: 10,
              paddingLeft: 10
            }}
          >
            We at Bitsbay have proven time and time again that our expertise as
            Chatbots developers is unrivaled. To develop a useful Chatbot for
            your business app, we interact with you to retrieve strategic
            information about your business’ operation. This helps us in
            creating an AI tool that is not only familiar with all of your
            business practices but also blends seamlessly with the app. We
            meticulously devise strategic conversational solutions which can
            bridge the gap between your brand and your users. When it comes to
            chatbots, clients have varying requirements, and we ensure we meet
            all their demands.
          </div>
          <div
            style={{
              marginTop: 30,
              fontWeight: 450
            }}
          ></div>

          <RequestButton2 title="I need Chabot services. Let’s discuss" />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 50,
          background: "#E0E1E3",
          paddingRight: 10,
          paddingLeft: 10
        }}
      >
        <div style={{ textAlign: responsive ? "" : "center" }}>
          <div style={{ fontSize: 25, fontWeight: 700, marginTop: "100" }}>
            Reasons to choose us
          </div>
          <div style={{ marginTop: 30, paddingRight: 10, paddingLeft: 10 }}>
            We not only provide development services, but ensure a great
            experience for our clients and aim to be your development partners.
            Our services include, but are
            <div>not limited to:</div>
          </div>
        </div>
        <Grid container>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 70
            }}
            item
            xs={12}
            md={5}
          >
            <div>
              <img
                width="100%"
                height="auto"
                src={require("../assets/ReasonsImg.png")}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            <div
              style={{
                display: responsive ? "" : "flex",
                justifyContent: responsive ? "" : "center",
                alignItems: responsive ? "" : "center",
                flexDirection: responsive ? "" : "column"
              }}
            >
              <div
                style={{
                  fontSize: responsive ? 17 : 14,
                  fontWeight: 400,
                  marginTop: 50
                }}
              >
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Idea protection by getting NDA signed
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 8 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Initial sessions to discuss strategy, features and pricing
                    plans
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Planning the project into phases with the project management
                    tool
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Wireframing and Prototyping to ensure UI is perfect for the
                    application
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Free strategy call to refine app features, plan marketing
                    and monetize options
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Flexible development procedures
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Pre-engineered boiler plates are used to aid development and
                    provide cost effective solutions
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Effective communication is maintained via Trello, Slack &
                    Skype
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>

                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Automated quality assurance checks are run over any work
                    produced
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>

                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Optimized and clean coding practices
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>

                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Code commits on a regular basis to ensure transparency
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>

                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Regular code commits to ensure transparency
                  </div>
                </div>
              </div>
              <RequestButton2 title="Lets connect" />
            </div>
          </Grid>
        </Grid>
      </div>

      <NineCards
        t1="PROJECT REQUIREMENTS
        "
        t2="USE CASES
        "
        t3="PROJECT PLAN
        "
        t4="WIREFRAMES & PROTOTYPES
        "
        t5="DESIGN
        "
        t6="AGILE DEVELOPMENT
        "
        t7="TESTING & QA
        "
        t8="RELEASE
        "
        t9="MAINTENANCE &
        SUPPORT"
        d1="A dedicated team member gathers project requirements from the client.

        "
        d2="The requirements are then used to draft use cases of the application.

        "
        d3="Project development is divided into sprints and managed via PM tool.

        "
        d4="App wireframes are developed for the client to get an idea of the final product.

        "
        d5="The wireframes are used as reference to create application designs.

        "
        d6="Project development is done sprint wise with regular code reviews and testing.

        "
        d7="After completion, QA testing is conducted to ensure there are no bugs.

        "
        d8="The product is then uploaded to live and made available to the target audience.

        "
        d9="Post app launch, we deliver maintenance, quality support and enhancement services as required.

        "
        img1={require("../assets/project.png")}
        img2={require("../assets/use.png")}
        img3={require("../assets/plan.png")}
        img4={require("../assets/Wireframing.png")}
        img5={require("../assets/design.png")}
        img6={require("../assets/agile.png")}
        img7={require("../assets/testing.png")}
        img8={require("../assets/release.png")}
        img9={require("../assets/maintenance.png")}
      />
      <div style={{ textAlign: responsive ? "" : "center" }}>
        <BuildAppSubmit />
        <Footer />
      </div>
    </>
  );
}

export default ChatbotDevelopment;
