import React from "react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Navbar from "./Navbar";
import { height } from "@mui/system";
import bitslogo2 from "../assets/bitslogo2.png";
import facebook from "../assets/facebook.png";
import twitter from "../assets/twitter.png";
import linkedin from "../assets/linkedin.png";
import "../css/global.css";
import MetaData from "./MetaData";
import theme from "../theme/color.json";

function Header() {
  const navigation = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const handleOpenModal = () => {
    navigation("/services");
  };
  const responsive = window.innerWidth > 500;

  return (
    <>
      <style>{dynamicCss}</style>
      <div className="Banner_wrapper">
        <div className="banner_content_wrapper">
          <div className="banner_logo">
            <img src={bitslogo2} className="img-fluid" />
          </div>
          <div className="banner_text">
            Impacting Future Markets by inventing top-notch solutions.
          </div>
          <div className="banner_text2">
            We take pride in enabling Future Markets with state-of-the-art
            digital solutions.
          </div>
          <div className="banner_social_media">
            <div className="b_social_items">
              <a href="https://www.facebook.com/bitsbayltd" target="_blank">
                <img src={facebook} className="img-fluid" />
              </a>
            </div>
            <div className="b_social_items">
              <a
                href="https://twitter.com/Bitsbayltd?t=Wt2l60yUeKwRIlyrmcKb2Q&s=09"
                target="_blank"
              >
                <img src={twitter} className="img-fluid" />
              </a>
            </div>
            <div className="b_social_items">
              <a
                href="https://www.linkedin.com/company/bitsbayltd/mycompany/?viewAsMember=true"
                target="_blank"
              >
                <img src={linkedin} className="img-fluid" />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/*<div id="header">
        <div className="mainContainer">
          <Navbar background styling />
          <div
            style={{ textAlign: responsive ? "" : "center" }}
            className="textContainer"
          >
            <MetaData
              title={
                " Bitsbay | Impacting Future Markets by inventing top-notch solutions."
              }
            />
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  width={responsive ? 350 : 250}
                  height={responsive ? 350 : 250}
                  src={require("../assets/bitslogo2.png")}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingLeft: responsive ? "" : 15,
                  paddingRight: responsive ? "" : 15,
                }}
              >
                <p
                  style={{ fontSize: responsive ? 27 : 22, fontWeight: "500" }}
                >
                  Impacting Future Markets by inventing top-notch solutions.
                </p>
                <p
                  style={{
                    marginTop: 10,
                    fontSize: responsive ? 20 : 15,
                    fontWeight: "400",
                  }}
                >
                  We take pride in enabling Future Markets with state-of-the-art
                  digital solutions.
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <div
                  style={{
                    marginRight: 15,
                    borderRadius: 30,
                    width: 40,
                    height: 40,
                    backgroundColor: "#0A0F20",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a href="https://www.facebook.com/bitsbayltd" target="_blank">
                    <img
                      width={30}
                      height={30}
                      src={require("../assets/facebook.png")}
                    />
                  </a>
                </div>
                <div
                  style={{
                    backgroundColor: "#0A0F20",
                    borderRadius: 30,
                    width: 40,
                    height: 40,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a
                    href="https://twitter.com/Bitsbayltd?t=Wt2l60yUeKwRIlyrmcKb2Q&s=09"
                    target="_blank"
                  >
                    <img
                      width={30}
                      height={30}
                      src={require("../assets/twitter.png")}
                    />
                  </a>
                </div>
                <div
                  style={{
                    marginLeft: 15,
                    backgroundColor: "#0A0F20",
                    borderRadius: 30,
                    width: 40,
                    height: 40,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a
                    href="https://www.linkedin.com/company/bitsbayltd/mycompany/?viewAsMember=true"
                    target="_blank"
                  >
                    <img
                      width={30}
                      height={30}
                      src={require("../assets/linkedin.png")}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>*/}
    </>
  );
}

export default Header;
const dynamicCss = `
.Banner_wrapper{
    border-color:${theme.borderWhite}
}
.b_social_items{
  background-color:${theme.iconRed}
}
.banner_text{
color:${theme.textWhite}
}
.banner_text2{
color:${theme.textWhite}
}
`;