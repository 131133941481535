import { Grid } from "@mui/material";
import React from "react";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import theme from "../theme/color.json";
function ThreeCards({
  title1,
  title2,
  title3,
  card1,
  card2,
  card3,
  img1,
  img2,
  img3,
  style1
}) {
  return (
    <>
      <style>{dynamicCss}</style>
      <Grid
        style={{
          display: "flex",
          justifyContent: "center"
        }}
        container
      >
        <Grid item xs={12} md={4}>
          <div className="cards_main" style={style1}>
            <img width={50} height={50} src={img1} />

            <div
              style={{
                fontWeight: 700,
                fontSize: 24,
                marginTop: 10
              }}
            >
              {title1}
            </div>
            <div  className="cards_main_text"
              style={{
               
                marginTop: 10,
                fontSize: 17
              }}
            >
              {card1}
            </div>
          </div>
        </Grid>
        <Grid style={{}} item xs={12} md={4}>
          <div  className="cards_main" style={style1}>
            <img style={{}} width={50} height={50} src={img2} />

            <div
              style={{
                fontWeight: 700,
                fontSize: 24,
                marginTop: 10
              }}
            >
              {title2}
            </div>
            <div  className="cards_main_text"
              style={{
               
                marginTop: 10,
                fontSize: 17
              }}
            >
              {card2}
            </div>
          </div>
        </Grid>
        <Grid style={{}} item xs={12} md={4}>
          <div  className="cards_main" style={style1}>
            <img style={{}} width={50} height={50} src={img3} />

            <div
              style={{
                fontWeight: 700,
                fontSize: 24,
                marginTop: 10
              }}
            >
              {title3}
            </div>
            <div  className="cards_main_text"
              style={{
               
                marginTop: 10,
                fontSize: 17
              }}
            >
              {card3}
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default ThreeCards;
const dynamicCss = `
.cards_main{
  background:${theme.bgWhite}
}
.cards_main_text{
  color:${theme.textGray}
}
`;