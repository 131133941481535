import React from "react";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import { Grid } from "@mui/material";
import theme from '../theme/color.json'
function TwoCards({ title1, title2, card1, card2, img1, img2 }) {
  return (
    <>
      <style>{dynamicCss}</style>
      <Grid
        style={{
          display: "flex",
          justifyContent: "center"
        }}
        container
      >
        <Grid
          className="cards_main"
          style={{
            margin: 12,
            borderRadius: 30
          }}
          item
          xs={12}
          md={5}
        >
          <div style={{ margin: 25 }}>
            <img width={70} height={70} src={img1} />

            <div
              style={{
                fontWeight: 700,
                fontSize: 24,
                marginTop: 20
              }}
            >
              {title1}
            </div>
            <div
              className="cards_main_text"
              style={{
                marginTop: 20,
                fontSize: 18
              }}
            >
              {card1}
            </div>
          </div>
        </Grid>
        <Grid
          className="cards_main"
          style={{
            margin: 12,
            borderRadius: 30
          }}
          item
          xs={12}
          md={5}
        >
          <div style={{ margin: 25 }}>
            <img style={{}} width={70} height={70} src={img2} />

            <div
              style={{
                fontWeight: 700,
                fontSize: 24,
                marginTop: 20
              }}
            >
              {title2}
            </div>
            <div
              className="cards_main_text"
              style={{
                marginTop: 20,
                fontSize: 18
              }}
            >
              {card2}
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default TwoCards;
const dynamicCss = `
.cards_main{
  background:${theme.bgWhite}
}
.cards_main_text{
  color:${theme.textGray}
}
`;