import React from "react";
import { Link } from "react-router-dom";
import "./project.css";
import logo2 from "../../assets/logo2.jpeg";
import logo1 from "../../assets/logo1.PNG";
import logo3 from "../../assets/logo3.PNG";
import logo5 from "../../assets/logo5.jfif";
import logo4 from "../../assets/logo4.PNG";
import logo6 from "../../assets/logo6.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import bassimart from "../../assets/bassimart.jpeg";
import basiimart2 from "../../assets/basiimart2.jpeg";
import bassimart4 from "../../assets/bassimart4.jpeg";
import bassimart1 from "../../assets/bassimart1.jpeg";
import taski from "../../assets/taski.jfif";
import taski1 from "../../assets/taski2.jfif";
import theme from '../../theme/color.json'
import teamImg from "../../assets/connection.jfif";
import novoImg from "../../assets/novo.jpeg";
import ss1 from "../../assets/ss1.jpg";
import ss2 from "../../assets/ss2.PNG";
import ss3 from "../../assets/ss3.PNG";
import nn1 from "../../assets/nn1.PNG";
import nn2 from "../../assets/nn2.PNG";
import nn3 from "../../assets/nn3.PNG";

const ProjectsSection = () => {
  const taskiData = {
    hide: true,

    projectDes:
      "Taskii Is A Task Management System Wherein Anyone Can Create A Task (electrician, Plumber, Service) And Anyone Can Bid On Tasks, It Follows Creator And Doer Framework, You Can Create Recurring Tasks And It Can Be Time Bound As Well. Both Crator And Doer Can Review Each Other.",

    projectDes3: " React/react-native,Mongodb, Google Firebase,Graphql, Nodejs",
    img: [
      {
        image: taski,
      },
      {
        image: taski1,
      },
    ],
  };

  const bassimartData = {
    hide: true,

    projectDes:
      "Basimart Makes It Easy To Order From Your Favorite Stores. Shop For Items From Stores Near You, With A Selection Of More Than 500 Retailers And Trusted Local Grocers Across Costa Rica",
    projectDes2:
      "Basimart Will Connect You With A Personal Shopper In Your Area To Shop And Deliver Your Order.",
    projectDes3: "React.js, Mongodb,google Firebase, Nodejs, Cloudinary",
    img: [
      {
        image: basiimart2,
      },
      {
        image: bassimart,
      },
      {
        image: bassimart1,
      },
    ],
  };
  const novoData = {
    hide: true,

    projectDes:
      "Novaapka Is A Shopping Mall Where Users May Earn Prizes By Spending A Certain Amount Of Money On Various Items. Users Can Then Use Their Points To Purchase Items. Bitsbay's Responsibility Was To Construct This App From Prototype To Store Release.",
    projectDes2:
      "Bitsbay's Responsibility Was To Construct This App From Prototype To Store Release, And We Completed The Project To The Client's Total Satisfaction. Now",
    projectDes3: "React native, firebase, cloud functions.",

    img: [
      {
        image: novoImg,
      },
    ],
  };
  const teamData = {
    hide: true,

    projectDes:
      "Teamconnection Is A Task Management App For An Oil Refinery Firm. The Corporation Can Post Company News, Assign Work To Different Employees, And Employees Can Read The News In The Feed And Check The Tasks Allocated To Them. Bitsbay Created The Entire System For It, Including The Admin Panel And Ios And Android Mobile Apps",

    projectDes3:
      "Mobile: react native, firebase, firestore,web: react js, firebase, firestor",

    img: [
      {
        image: teamImg,
      },
    ],
  };
  const seizedData = {
    hide: true,

    projectDes:
      "On the gaming e-commerce site PlaySiezd, you may buy things by taking part in games. There is an entry charge that fluctuates based on the market worth of the goods. You can earn credit by playing games, and you can use that credit to buy goods.",

    projectDes3: "react js , firebase, firestore",

    img: [
      {
        image: ss1,
      },
      {
        image: ss2,
      },
      {
        image: ss3,
      },
    ],
  };
  const MusicData = {
    hide: false,

    img: [
      {
        image: nn1,
      },
      {
        image: nn2,
      },
      {
        image: nn3,
      },
    ],
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Click me for more details
    </Tooltip>
  );
  return (
    <>
      <style>{dynamicCss}</style>
      <div className="portfolio9_wrapper">
        <div className="portflio_left">
          <div className="portfolio_heading">Web Application</div>
          <div className="portfilio_box">
            <Link className="link_type" to="/project-demo" state={seizedData}>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 200 }}
                overlay={renderTooltip}
              >
                <div className="portfolio_items shadow">
                  <img src={logo2} className="img-fluid logo_ss" />
                </div>
              </OverlayTrigger>
            </Link>
            <Link
              className="link_type"
              to="/project-demo"
              state={bassimartData}
            >
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 200 }}
                overlay={renderTooltip}
              >
                <div className="portfolio_items shadow">
                  <img src={logo5} className="img-fluid logo_ss" />
                </div>
              </OverlayTrigger>
            </Link>
            {/*<div className="portfolio_items shadow">WebSites</div>
            <div className="portfolio_items shadow">WebSites</div>*/}
          </div>
        </div>
        <div className="portfolio_seprator">
          <div className="seprate_point1"></div>
          <div className="seprate_point2"></div>
        </div>
        <div className="portflio_left">
          <div className="portfolio_heading">Mobile Application</div>
          <div className="portfilio_box">
            <Link className="link_type" to="/project-demo" state={novoData}>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 200 }}
                overlay={renderTooltip}
              >
                <div className="portfolio_items shadow">
                  <img src={logo1} className="img-fluid logo_ss" />
                </div>
              </OverlayTrigger>
            </Link>
            <Link className="link_type" to="/project-demo" state={teamData}>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 200 }}
                overlay={renderTooltip}
              >
                <div className="portfolio_items shadow">
                  <img src={logo3} className="img-fluid logo_ss" />
                </div>
              </OverlayTrigger>
            </Link>
            <Link className="link_type" to="/project-demo" state={MusicData}>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 200 }}
                overlay={renderTooltip}
              >
                <div className="portfolio_items shadow">
                  <img src={logo4} className="img-fluid logo_ss" />
                </div>
              </OverlayTrigger>
            </Link>
            <Link className="link_type" to="/project-demo" state={taskiData}>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 200 }}
                overlay={renderTooltip}
              >
                <div className="portfolio_items shadow">
                  <img src={logo6} className="img-fluid logo_ss" />
                </div>
              </OverlayTrigger>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectsSection;
const dynamicCss = `
.portfolio_heading{
  color:${theme.textRed}
}
.portfolio_items:hover{
  border-color:${theme.borderSkyBlue}
}
.portfolio_seprator{
  border-color:${theme.borderRed}
}
.seprate_point1{
  background:${theme.bgRed}
}
.seprate_point2{
  background:${theme.bgRed}
}
`;