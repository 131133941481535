import { Grid } from "@mui/material";
import React, { useState } from "react";
import Footer from "../Components/Footer";
import MetaData from "../Components/MetaData";
import Navbar from "../Components/Navbar";
import ProjectsSection from "../Components/Porjects";
import NavBarSec from "../Components/NavBar2";
import project2 from "../assets/project2.png";
import theme from '../theme/color.json'

import ProjectDetailModal from "../Components/ProjectDetailModal";
const data = [
  {
    id: "1",
    projectDes:
      "Afrocorner Is A Platform Where Businesses From All Over The World May Register Andoffer Their Services, And Clients Can Search For The Services They Desire And Contact The",

    productDes3: "React Native, Firebase, Cloud Functions, Typescript.",

    img: [
      {
        id: "1",
        img: require("../assets/afro.jpeg"),
      },
      {
        id: "1",
        img: require("../assets/afroSlide1.jpeg"),
      },
      {
        img: require("../assets/afroSlide3.jpeg"),
      },
    ],
    data: [
      {
        img: require("../assets/afroSlide1.jpeg"),
      },
      {
        img: require("../assets/afroSlide2.jpeg"),
      },
      {
        img: require("../assets/afroSlide3.jpeg"),
      },
      {
        img: require("../assets/afroSlide4.jpeg"),
      },
    ],
  },
  {
    projectDes:
      "Novaapka Is A Shopping Mall Where Users May Earn Prizes By Spending A Certain Amount Of Money On Various Items. Users Can Then Use Their Points To Purchase Items. Bitsbay's Responsibility Was To Construct This App From Prototype To Store Release.",
    productDes2:
      "Bitsbay's Responsibility Was To Construct This App From Prototype To Store Release, And We Completed The Project To The Client's Total Satisfaction. Now",
    productDes3: "React native, firebase, cloud functions.",

    img: [
      {
        img: require("../assets/novo.jpeg"),
      },
    ],
    data: [
      {
        img: require("../assets/novo.jpeg"),
      },
    ],
  },
  {
    projectDes:
      "Teamconnection Is A Task Management App For An Oil Refinery Firm. The Corporation Can Post Company News, Assign Work To Different Employees, And Employees Can Read The News In The Feed And Check The Tasks Allocated To Them. Bitsbay Created The Entire System For It, Including The Admin Panel And Ios And Android Mobile Apps",

    productDes3:
      "Mobile: react native, firebase, firestore,web: react js, firebase, firestor",

    img: [
      {
        img: require("../assets/teamConnection1.jpg"),
      },
    ],
    data: [
      {
        img: require("../assets/teamConnection.jpg"),
      },
      {
        img: require("../assets/teamConnection1.jpg"),
      },
    ],
  },
  {
    id: "2",

    projectDes:
      "Food delivery mobile app which shows restaurants and food points near user’s current location with additional kitchen’s live streaming feature to check either covid-19 sops are followed or not.",

    productDes3: "React Native, Firebase, Firestore",

    img: [
      {
        img: require("../assets/foodBeast.jpg"),
      },
      {
        img: require("../assets/foodBeast1.jpg"),
      },
      {
        img: require("../assets/foodBeast3.jpg"),
      },
    ],
    data: [
      {
        img: require("../assets/foodBeast.jpg"),
      },
      {
        img: require("../assets/foodBeast1.jpg"),
      },
      {
        img: require("../assets/foodBeast2.jpg"),
      },
      {
        img: require("../assets/foodBeast3.jpg"),
      },
    ],
  },
  {
    projectDes:
      "An In-house Content Management System With Hierarchy Mechanism Implemented In It For Access Management eveloped For Sumico.",

    productDes3: "NET MVC, SQL",

    img: [
      {
        img: require("../assets/DisLikr.jpg"),
      },
    ],
    data: [
      {
        img: require("../assets/DisLikr.jpg"),
      },
      {
        img: require("../assets/DisLikr1.jpg"),
      },
      {
        img: require("../assets/DisLikr2.jpg"),
      },
      {
        img: require("../assets/DisLikr3.jpg"),
      },
    ],
  },
  {
    id: "3",

    projectDes:
      "Taskii Is A Task Management System Wherein Anyone Can Create A Task (electrician, Plumber, Service) And Anyone Can Bid On Tasks, It Follows Creator And Doer Framework, You Can Create Recurring Tasks And It Can Be Time Bound As Well. Both Crator And Doer Can Review Each Other.",

    productDes3: " React/react-native,Mongodb, Google Firebase,Graphql, Nodejs",

    img: [
      {
        img: require("../assets/taski.jpeg"),
      },
      {
        img: require("../assets/taski4.jpeg"),
      },
      {
        img: require("../assets/taski3.jpeg"),
      },
    ],
    data: [
      {
        img: require("../assets/taski.jpeg"),
      },
      {
        img: require("../assets/taski1.jpeg"),
      },
      {
        img: require("../assets/taski2.jpeg"),
      },
      {
        img: require("../assets/taski3.jpeg"),
      },
      {
        img: require("../assets/taski4.jpeg"),
      },
      {
        img: require("../assets/taski5.jpeg"),
      },
      {
        img: require("../assets/taski6.jpeg"),
      },
      {
        img: require("../assets/taski7.jpeg"),
      },
    ],
  },
  {
    projectDes:
      "Basimart Makes It Easy To Order From Your Favorite Stores. Shop For Items From Stores Near You, With A Selection Of More Than 500 Retailers And Trusted Local Grocers Across Costa Rica",
    productDes2:
      "Basimart Will Connect You With A Personal Shopper In Your Area To Shop And Deliver Your Order.",
    productDes3: "React.js, Mongodb,google Firebase, Nodejs, Cloudinary",

    img: [
      {
        img: require("../assets/bassimart.jpeg"),
      },
    ],
    data: [
      {
        img: require("../assets/bassimart.jpeg"),
      },
      {
        img: require("../assets/bassimart1.jpeg"),
      },

      {
        img: require("../assets/bassimart3.jpeg"),
      },
      {
        img: require("../assets/bassimart4.jpeg"),
      },
      {
        img: require("../assets/bassimart5.jpeg"),
      },
      {
        img: require("../assets/bassimart6.jpeg"),
      },
    ],
  },
];

function Projects() {
  const [open, setOpen] = React.useState(false);
  const [allData, setAllData] = React.useState();
  const [allText, setAllText] = React.useState("");
  const [allText2, setAllText2] = React.useState("");

  const [allText3, setAllText3] = React.useState("");
  const [docId, setDocId] = React.useState("");

  const handleOpen = (data, text1, text2, text3, id) => {
    setAllData(data);
    setAllText(text1);
    setAllText2(text2);
    setAllText3(text3);
    setDocId(id);

    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  return (
    <div>
      <style>{dynamicCss}</style>
      <MetaData title={"Bitsbay | Projects"} />

      <ProjectDetailModal
        open={open}
        handleClose={handleClose}
        allData={allData}
        allText={allText}
        allText2={allText2}
        allText3={allText3}
        docId={docId}
      />
      <NavBarSec />

      <div className="featured_projects_box">
        <div className="banner_headings">Featured Projects</div>
      </div>
      <ProjectsSection />

      <Footer />
    </div>
  );
}

export default Projects;
const dynamicCss = `
.banner_headings{
  color:${theme.textWhite}
}
`