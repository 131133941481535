import { Grid } from "@mui/material";
import React from "react";
import BuildAppSubmit from "../Components/BuildAppSubmit";
import Footer from "../Components/Footer";
import MetaData from "../Components/MetaData";
import Navbar from "../Components/Navbar";
import NineCards from "../Components/NineCards";
import RequestButton from "../Components/RequestButton";
import NavBarSec from "../Components/NavBar2";
import RequestButton2 from "../Components/RequestButton2";

function AugmentedRealityDevelopment() {
  const responsive = window.innerWidth > 500;
  return (
    <>
      <NavBarSec />
      <div style={{ textAlign: responsive ? "" : "center" }}>
        <MetaData title={"Building your visions. Creating reality."} />
        <Grid
          md={12}
          style={{
            background: "#61ACC1",
            maxWidth: "100%",
            minHeight: 650,
            display: "flex",
            justifyContent: "center"
          }}
          container
        >
          <Grid item xs={12} md={6}>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: responsive ? "" : 10
              }}
            >
              <div style={{ color: "white" }}>
                <div
                  style={{
                    fontSize: responsive ? 40 : 26,
                    fontWeight: 700,
                    marginRight: responsive ? 0 : 10,
                    marginLeft: responsive ? 0 : 10
                  }}
                >
                  Building your visions. Creating reality.
                </div>

                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    marginTop: 10,
                    marginRight: responsive ? 0 : 10,
                    marginLeft: responsive ? 0 : 10
                  }}
                >
                  Bitsbay specializes in augmented reality app development
                  allowing businesses, industries, and organizations to carry
                  out life-like simulations of their basic operations without
                  any issues.
                </div>
                <RequestButton />
              </div>
            </div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div style={{ marginTop: 70 }}>
              <img
                width="100%"
                height="100%"
                src={require("../assets/Augmented.png")}
              />
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            paddingTop: 50,
            paddingLeft: 10,
            paddingRight:10
          }}
        >
          <div>
            <div
              style={{
                fontSize: 25,
                fontWeight: 630
              }}
            >
              ENGAGING
            </div>
            <div
              style={{
                fontSize: 25,
                fontWeight: 700
              }}
            >
              Augmented Reality Solutions
            </div>
            <div
              style={{
                marginTop: 30,
                fontWeight: 450,
                paddingLeft: 10,
                paddingRight: 10
              }}
            >
              The demand for AR technology continues to increase at a steady
              pace with more industries incorporating it into their operations.
              Augmented Reality is no longer confined to the gaming industry. It
              has been modified effectively and extended its application to
              other tech-related sectors of the economy.
              <div
                style={{
                  marginTop: 20,
                  fontSize: 25,
                  fontWeight: 700
                }}
              >
                Torch Bearers Of Augmented Reality
              </div>
              <div
                style={{
                  marginTop: 30,
                  fontWeight: 450,
                  paddingRight: 10,
                  paddingLeft: 10
                }}
              >
                At Bitsbay, we provide variations of augmented reality app
                development services for clients looking to fine-tune the
                operations of their businesses and increase efficiency. We can
                create all four types of AR programs like marker-based augmented
                reality, Markerless Augmented Reality, Projection Augmented
                Reality and Superimposition Based Augmented Reality. We possess
                all the necessary tools to help you complete an AR installation
                and test-run it to eliminate bugs. Our team of highly trained
                app developers has the expertise to execute exemplary Augmented
                Reality app development and a robust portfolio to back their
                skill. We develop AR apps that are not only believable but
                practical as well, ensuring quality standard demanded by your
                users.
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: 20,
              fontWeight: 450
            }}
          ></div>

          <RequestButton2 title="Share your AR app idea. Talk to us" />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: 50,
          background: "#E0E1E3",
          paddingRight: 10,
          paddingLeft:10
        }}
      >
        <div
          style={{
            marginLeft: responsive ? 180 : "",
            textAlign: responsive ? "" : "center"
          }}
        >
          <div style={{ fontSize: 25, fontWeight: 700 }}>
            Reasons to choose us
          </div>
          <div style={{ marginTop: 30, paddingRight: 10, paddingLeft: 10 }}>
            Our outstanding end-to-end project delivery services are what set us
            apart and ensure client satisfaction.
          </div>
        </div>
        <Grid container>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 70
            }}
            item
            xs={12}
            md={5}
          >
            <div>
              <img
                width="100%"
                height="auto"
                src={require("../assets/ReasonsImg.png")}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            <div
              style={{
                display: responsive ? "" : "flex",
                justifyContent: responsive ? "" : "center",
                alignItems: responsive ? "" : "center",
                flexDirection: responsive ? "" : "column"
              }}
            >
              <div
                style={{
                  fontSize: responsive ? 17 : 14,
                  fontWeight: 400,
                  marginTop: 50
                }}
              >
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Your idea remains secure with us with a signed NDA
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 8 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Free session to discuss project strategy, application
                    features and pricing plans
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    We tailor and customize our plans according to your needs
                    and requirements
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    The AR project plan is mapped on project management tools
                    and divided into sprints{" "}
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    We provide AR Application prototyping to the client to
                    orchestrate an idea of the look and feel
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Since we use agile development, there is plenty of room for
                    flexibility for the client to share their concerns
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    We use pre-engineered boilerplates for development which
                    reduces time and cost
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Through Trello or Slack, smooth flow of communication is
                    carried out{" "}
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>

                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Automated, as well as manual quality assurance checks, are
                    run on the application development
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>

                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    We deliver end-to-end services for deployment as well as
                    testing
                  </div>
                </div>
              </div>
              <RequestButton2 title="Lets connect" />
            </div>
          </Grid>
        </Grid>
      </div>

      <NineCards
        t1="PROJECT REQUIREMENTS
        "
        t2="USE CASES
        "
        t3="PROJECT PLAN
        "
        t4="WIREFRAMES & PROTOTYPES
        "
        t5="DESIGN
        "
        t6="AGILE DEVELOPMENT
        "
        t7="TESTING & QA
        "
        t8="RELEASE
        "
        t9="MAINTENANCE &
        SUPPORT"
        d1="A dedicated team member gathers project requirements from the client.

        "
        d2="The requirements are then used to draft use cases of the application.

        "
        d3="Project development is divided into sprints and managed via PM tool.

        "
        d4="App wireframes are developed for the client to get an idea of the final product.

        "
        d5="The wireframes are used as reference to create application designs.

        "
        d6="Project development is done sprint wise with regular code reviews and testing.

        "
        d7="After completion, QA testing is conducted to ensure there are no bugs.

        "
        d8="The product is then uploaded to live and made available to the target audience.

        "
        d9="Post app launch, we deliver maintenance, quality support and enhancement services as required.

        "
        img1={require("../assets/project.png")}
        img2={require("../assets/use.png")}
        img3={require("../assets/plan.png")}
        img4={require("../assets/Wireframing.png")}
        img5={require("../assets/design.png")}
        img6={require("../assets/agile.png")}
        img7={require("../assets/testing.png")}
        img8={require("../assets/release.png")}
        img9={require("../assets/maintenance.png")}
      />
      <div style={{ textAlign: responsive ? "" : "center" }}>
        <BuildAppSubmit />
        <Footer />
      </div>
    </>
  );
}

export default AugmentedRealityDevelopment;
