import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Legacy from "../Components/Legacy";
import MetaData from "../Components/MetaData";
import ModalButton from "../Components/ModalButton";
import NavBarSec from "../Components/NavBar2";

function HomeScreen() {
  const responsive = window.innerWidth > 500;

  return (
    <>
      <MetaData title={"bitsbayltd"} />
      <div>
        <NavBarSec />
        <Header />
        <Legacy />
        <ModalButton />
        <Footer />
      </div>
    </>
  );
}

export default HomeScreen;
