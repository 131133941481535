import { Grid } from "@mui/material";
import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Navbar from "../Components/Navbar";
import "../css/Service.css";
function Services() {
  return (
    <div>
      <Navbar styling />
      <div style={{ backgroundColor: "#F5F9FA", minHeight: "160vh" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            paddingTop: 100,
          }}
        >
          <div style={{ fontSize: 20, fontWeight: 700 }}>SERVICES</div>
          <div style={{ fontSize: 40, fontWeight: 700 }}>
            Core Project Competencies
          </div>
          <div style={{ fontWeight: 500, marginTop: 70, fontSize: 17 }}>
            Our trusted partner of modern enterprises, SMBs and startups. We
            provide full-cycle custom software development services that lead
            companies
          </div>
          <div style={{ fontWeight: 500, fontSize: 17 }}>
            to growth and sustainability. With a proven record of delivering
            beautiful software products and consultancy, we are here to offer
            you services and
          </div>
          <div style={{ fontWeight: 500, fontSize: 17 }}>
            strategies that drive innovation, build brand equity, and increase
            your revenue.
          </div>
        </div>
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 100,
          }}
          container
        >
          <Grid className="border" item md={3.1} xs={12}>
            <div
              style={{
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 30,
                borderColor: "red",
              }}
            >
              <div>
                <img
                  width="100px"
                  height="200px"
                  src="https://cdn-ekfhe.nitrocdn.com/jSSAXXcuXnWaotMvWufiDcOqvAQCjqgn/assets/static/optimized/rev-580ddb9/wp-content/uploads/2018/10/mobile-apps.svg"
                />
              </div>

              <div>App development</div>
            </div>
          </Grid>
          <Grid className="border" item md={3.1} xs={12}>
            <div
              style={{
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 30,
              }}
            >
              <div>
                <img
                  width="100px"
                  height="200px"
                  src="https://cdn-ekfhe.nitrocdn.com/jSSAXXcuXnWaotMvWufiDcOqvAQCjqgn/assets/static/optimized/rev-580ddb9/wp-content/uploads/2018/10/mobile-apps.svg"
                />
              </div>
              <div>App development</div>
            </div>
          </Grid>
          <Grid className="borderBottom" item md={3.1} xs={12}>
            <div
              style={{
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 30,
              }}
            >
              <div>
                <img
                  width="100px"
                  height="200px"
                  src="https://cdn-ekfhe.nitrocdn.com/jSSAXXcuXnWaotMvWufiDcOqvAQCjqgn/assets/static/optimized/rev-580ddb9/wp-content/uploads/2018/10/mobile-apps.svg"
                />
              </div>
              <div>App development</div>
            </div>
          </Grid>
          <Grid className="borderLeft" item md={3.1} xs={12}>
            <div
              style={{
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 30,
              }}
            >
              <div>
                <img
                  width="100px"
                  height="200px"
                  src="https://cdn-ekfhe.nitrocdn.com/jSSAXXcuXnWaotMvWufiDcOqvAQCjqgn/assets/static/optimized/rev-580ddb9/wp-content/uploads/2018/10/mobile-apps.svg"
                />
              </div>
              <div>App development</div>
            </div>
          </Grid>
          <Grid className="borderLeft" item md={3.1} xs={12}>
            <div
              style={{
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 30,
              }}
            >
              <div>
                <img
                  width="100px"
                  height="200px"
                  src="https://cdn-ekfhe.nitrocdn.com/jSSAXXcuXnWaotMvWufiDcOqvAQCjqgn/assets/static/optimized/rev-580ddb9/wp-content/uploads/2018/10/mobile-apps.svg"
                />
              </div>
              <div>App development</div>
            </div>
          </Grid>
          <Grid item md={3.1} xs={12}>
            <div
              style={{
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 30,
              }}
            >
              <div>
                <img
                  width="100px"
                  height="200px"
                  src="https://cdn-ekfhe.nitrocdn.com/jSSAXXcuXnWaotMvWufiDcOqvAQCjqgn/assets/static/optimized/rev-580ddb9/wp-content/uploads/2018/10/mobile-apps.svg"
                />
              </div>
              <div>App development</div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: 60,
        }}
      >
        <div style={{ fontSize: 20, fontWeight: 700 }}> OUR CLIENTS</div>
        <div style={{ fontSize: 35, fontWeight: 700, marginTop: 30 }}>
          It’s Your Turn To Join The League Of
        </div>
        <div style={{ marginTop: 20 }}>
          React Native development enables businesses to accelerate the process
          of building apps across different platforms, thanks to the possibility
          of
        </div>
        <div>reusing most of the code between them.</div>
      </div>
      <Footer />
    </div>
  );
}

export default Services;
