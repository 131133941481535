import React from "react";
import "../styles/requestButton.css"
import theme from '../theme/color.json'

const RequestButton = () => {
  const responsive = window.innerWidth > 500;

  return (
    <>
      <style>{dynamicCss}</style>
    <div
      style={{ display: responsive ? "" : "flex", justifyContent: "center" }}
    >
      {responsive ? (
        <a
          className="request_button_web"
          href={"#class"}
     
        >
          Request Free Consultation
        </a>
      ) : (
        <a className="request_button_mobile" href={"#class"}>
          Request Free Consultation
        </a>
      )}
    </div>
    </>
  );
};

export default RequestButton;
const dynamicCss = `
.request_button_web{
  color:${theme.textWhite};
  background:${theme.bgRed};
}
.request_button_web:hover{
  color:${theme.textWhite}
}
`;