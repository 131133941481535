import React, { useRef, useState } from "react";
import { Grid } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Box from "@mui/material/Box";
import MailIcon from "@mui/icons-material/Mail";
import Modal from "@mui/material/Modal";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import emailjs from "emailjs-com";

const loginSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  email: Yup.string().email().required("An email is required"),
  phone: Yup.string().required("This field is required"),
  message: Yup.string().required("This field is required"),
});
const style = {
  marginTop: 10,
  margin: 2,
};
function BuildAppSubmit() {
  // const captchaRef = useRef(null);
  const formref = useRef();
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSubmit = async (values) => {
    // var data = {
    //   service_id: "service_524jcfc",
    //   template_id: "template_c17sh2l",
    //   user_id: "L8E3OR3Pt0z9SXHOS",
    //   template_params: values,
    // };
    // const token = captchaRef.current.getValue();

    // captchaRef.current.reset();
    // console.log(token, "token");
    if (true) {
      // const endPoint =
      //   "https://public.herotofu.com/v1/d25bfba0-6f4a-11ed-a377-655c67143cec";
      // console.log(values, "myValues");
      // fetch(endPoint, {
      //   method: "POST",
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify(values),
      // }).then((res) => {
      //   console.log(res, "res");
      //   setOpen(true);
      //   // captchaRef.current.reset();
      // });
      const serviceId = "service_wfyb3vi";
      const templateId = "template_oopj4to";
      const userId = "o1sGgvXOrjeloB-wp";

      const response = await emailjs.send(
        serviceId,
        templateId,
        {
          name: values.name,
          email: values.email,
          phone: values.phone,
          message: values.message,
        },
        userId
      );

      if (response.status === 200) {
        console.log("Email sent successfully", response.status, response.text);
        console.log("Email sent successfully", response.status, response.text);
      } else {
        console.error("Failed to send email", response.status, response.text);
        console.error("Failed to send email", response.status, response.text);
      }
      console.log(values, "myValues");
      setVerified(true);
      setError(false);
    } else {
      setError(false);
    }
  };
  const REACT_APP_SITE_KEY = "6Ldn-RsjAAAAANh4UiIymBwFiS2gkS_0o_B0kSSc";
  return (
    <div style={{backgroundColor:"red"}}  id="class">
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Alert
              severity="success"
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              We will contact soon
            </Alert>
          </Box>
        </Modal>
      </div>
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          width: "100%",
          marginTop: 20,
        }}
      >
        <div
          style={{ backgroundColor: "#FAFAFA", paddingTop: 100, width: "100%" }}
        >
          <div style={{ minHeight: 650 }} className="gradient">
            <Grid md={12} xs={12} container>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
                item
                md={6}
              >
                <div
                  style={{
                    marginLeft: 100,
                    marginRight: 150,
                    color: "white",
                    fontSize: 30,
                    fontWeight: 700,
                    marginTop: 20,
                  }}
                >
                  Are you ready to build an app with Wow factor?
                </div>
                <a
                  href="mailto:support@bitsbayltd.com"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 100,
                    marginRight: 150,
                    marginTop: 20,
                    textDecoration: "none",
                  }}
                >
                  <MailIcon style={{ marginRight: 20, color: "white" }} />
                  <div style={{ color: "white", fontWeight: "600" }}>
                    solutions@bitsbayltd.com
                  </div>
                </a>
                <a
                  href="tel:+923160499968"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 100,
                    marginRight: 150,
                    marginTop: 20,
                    textDecoration: "none",
                  }}
                >
                  <LocalPhoneIcon style={{ marginRight: 20, color: "white" }} />
                  <div style={{ color: "white", fontWeight: "600" }}>
                    +1 956 3516004
                  </div>
                </a>
              </Grid>
              <Grid
                style={{
                  backgroundColor: "white",
                  marginTop: -140,
                  boxShadow: "1px 2px 9px gray",
                }}
                item
                md={5}
              >
                <Formik
                  innerRef={formref}
                  initialValues={{
                    name: "",
                    email: "",
                    phone: "",
                    message: "",
                  }}
                  validationSchema={loginSchema}
                  onSubmit={(values, { resetForm }) => {
                    handleSubmit(values);
                    resetForm();
                  }}
                >
                  <Form>
                    <div
                      style={{
                        marginLeft: 50,
                        marginRight: 50,
                        marginTop: 50,
                        marginBottom: 50,
                      }}
                    >
                      <Field
                        style={{ marginTop: 10, marginBottom: 10 }}
                        component={TextField}
                        margin="normal"
                        fullWidth
                        name="name"
                        label="Your Name"
                        type="name"
                        id="name"
                        disabled={false}
                      />
                      <div
                        style={{
                          display: "flex",
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Field
                          style={{
                            marginRight: 10,
                          }}
                          component={TextField}
                          margin="normal"
                          fullWidth
                          required
                          id="email"
                          label="Email"
                          name="email"
                          disabled={false}
                        />
                        <Field
                          component={TextField}
                          margin="normal"
                          fullWidth
                          name="phone"
                          label="Phone"
                          type="phone"
                          id="phone"
                          disabled={false}
                        />
                      </div>
                      <Field
                        component={TextField}
                        style={{
                          width: "99%",
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                        placeholder="Tell us about your product and business challenge."
                        rows="7"
                        margin="normal"
                        name="message"
                        label="Message"
                        type="message"
                        multiline
                        // rows={4}
                        disabled={false}
                      />
                      {/* <ReCAPTCHA
                        sitekey={REACT_APP_SITE_KEY}
                        ref={captchaRef}
                      /> */}
                      {error && (
                        <div
                          style={{
                            fontSize: 15,
                            color: "red",
                            fontWeight: 600,
                          }}
                        >
                          Fill out the captcha
                        </div>
                      )}
                      <button
                        disabled={verified}
                        type="submit"
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#D22931",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                          padding: 12,
                          color: "white",
                          marginTop: 10,
                          marginBottom: 20,
                          textTransform: "none",
                          borderWidth: 0,
                          fontSize: 18,
                        }}
                      >
                        <div>Submit</div>
                      </button>
                    </div>
                  </Form>
                </Formik>
              </Grid>
            </Grid>
          </div>
        </div>
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" }, width: "100%" }}>
        <div style={{ backgroundColor: "#FAFAFA", width: "100%" }}>
          <div
            style={{
              minHeight: 600,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="gradient"
          >
            <Grid md={12} xs={12} container>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                item
                md={6}
                xs={12}
              >
                <div
                  style={{
                    marginLeft: 50,
                    marginRight: 50,
                    color: "white",
                    fontSize: 30,
                    fontWeight: 700,
                    marginTop: 20,
                  }}
                >
                  Are you ready to build an app with Wow factor?
                </div>
                <a
                  href="mailto:nnfarooq00@gmail.com"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 50,
                    marginRight: 50,
                    marginTop: 20,
                  }}
                >
                  <MailIcon style={{ marginRight: 20, color: "white" }} />
                  <div style={{ color: "white", fontWeight: "600" }}>
                    nnfarooq00@gmail.com
                  </div>
                </a>
                <a
                  href="tel:(888)4058645"
                  style={{
                    display: "flex",
                    alignItems: "center",

                    marginRight: 50,
                    marginTop: 20,
                  }}
                >
                  <LocalPhoneIcon style={{ marginRight: 20, color: "white" }} />
                  <div style={{ color: "white", fontWeight: "600" }}>
                    (888) 405-8645
                  </div>
                </a>
              </Grid>
              <Grid
                style={{
                  backgroundColor: "white",
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 50,
                  marginBottom: 40,
                }}
                item
                xs={12}
              >
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    phone: "",
                    message: "",
                  }}
                  onSubmit={(values, { resetForm }) => {
                    handleSubmit(values);
                    resetForm();
                  }}
                >
                  <Form>
                    <div
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        marginTop: 50,
                        marginBottom: 50,
                      }}
                    >
                      <Field
                        style={{ marginTop: 10, marginBottom: 10 }}
                        component={TextField}
                        margin="normal"
                        fullWidth
                        name="name"
                        label="name"
                        type="name"
                        id="name"
                        disabled={false}
                      />
                      <div
                        style={{
                          display: "flex",
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Field
                          style={{
                            marginRight: 10,
                          }}
                          component={TextField}
                          margin="normal"
                          fullWidth
                          required
                          id="email"
                          label="Email"
                          name="email"
                          disabled={false}
                        />
                        <Field
                          component={TextField}
                          margin="normal"
                          fullWidth
                          name="phone"
                          label="phone"
                          type="phone"
                          id="phone"
                          disabled={false}
                        />
                      </div>
                      <Field
                        style={{
                          width: "99%",
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                        component="textarea"
                        placeholder="Tell us about your product and business challenge."
                        rows="7"
                        margin="normal"
                        name="message"
                        label="message"
                        type="message"
                        id="message"
                        disabled={false}
                      />
                      {/* <ReCAPTCHA
                        sitekey={REACT_APP_SITE_KEY}
                        ref={captchaRef}
                      /> */}
                      {error && (
                        <div
                          style={{
                            fontSize: 15,
                            color: "red",
                            fontWeight: 600,
                          }}
                        >
                          Fill out the captcha
                        </div>
                      )}
                      <button
                        disabled={verified}
                        type="submit"
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#D22931",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                          padding: 12,
                          color: "white",
                          marginTop: 10,
                          marginBottom: 20,
                          textTransform: "none",
                          borderWidth: 0,
                          fontSize: 18,
                        }}
                      >
                        <div>Submit</div>
                      </button>
                    </div>
                  </Form>
                </Formik>
              </Grid>
            </Grid>
          </div>
        </div>
      </Box>
    </div>
  );
}

export default BuildAppSubmit;
