import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import ThreeCards from "../Components/ThreeCards";
import NavBarSec from "../Components/NavBar2";
import MetaData from "../Components/MetaData";

function PressRelease() {
  return (
    <div>
      <MetaData title={"Bitsbay | Resource "} />

      <NavBarSec />
      <di000v
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
          marginTop: 100,
        }}
      >
        <div style={{ fontSize: 40, fontWeight: 700 }}>Press Releases</div>
      </di000v>

      <Footer />
    </div>
  );
}

export default PressRelease;
