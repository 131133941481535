import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import ThreeCards from "../Components/ThreeCards";
import NineCards from "../Components/NineCards";
import BuildAppSubmit from "../Components/BuildAppSubmit";
import MetaData from "../Components/MetaData";
import NavBarSec from "../Components/NavBar2";
import RequestButton from "../Components/RequestButton";
import RequestButton2 from "../Components/RequestButton2";
import theme from "../theme/color.json";

function ProgressiveWebApps() {
  const responsive = window.innerWidth > 500;
  return (
    <>
      <style>{dynamicCss}</style>
      <div className="text_black">
        <NavBarSec />
        <div style={{ textAlign: responsive ? "" : "center" }}>
          <MetaData
            title={"PROGRESSIVE WEB APPS that moves your company to the future"}
          />
          <Grid
            md={12}
            style={{
              // minHeight: 600,
              display: "flex",
              justifyContent: "center"
            }}
            container
            className="progressive_app_header"
          >
            <Grid item xs={12} md={5}>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginLeft: responsive ? "" : 5,
                  marginRight: responsive ? "" : 5,
                  marginTop: responsive ? "" : 10
                }}
              >
                <div className="progressive_app_header_text">
                  <div
                    style={{ fontSize: responsive ? 40 : 25, fontWeight: 700 }}
                  >
                    PROGRESSIVE WEB APPS that moves your company to the future
                  </div>
                  <div
                    style={{
                      fontSize: 17,
                      fontWeight: 600,
                      marginTop: 10,
                      marginRight: responsive ? 0 : 10,
                      marginLeft: responsive ? 0 : 10
                    }}
                  >
                    Leverage the power of the latest technology with our
                    development services for progressive web apps.
                  </div>
                  <RequestButton />
                </div>
              </div>
            </Grid>
            <Grid item xs={10} md={5}>
              <div style={{ marginTop: 30 }}>
                <img width="100%" src={require("../assets/Progressive.png")} />
              </div>
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              paddingTop: 40,
              paddingLeft: 10,
              paddingRight:10
            }}
          >
            <div className="text_black">
              <div
                style={{
                  fontSize: 25,
                  fontWeight: 630
                }}
              >
                Fast & Engaging
              </div>
              <div
                style={{
                  fontSize: 25,
                  fontWeight: 700
                }}
              >
                Progressive Web App Development
              </div>
              <div
                style={{
                  marginTop: 30,
                  fontWeight: 400
                }}
              >
                <div
                  style={{
                    paddingLeft: 10,
                    paddingRight: 10
                  }}
                >
                  Progressive Web Apps are defining the future of app
                  development. As a result of this emerging technology, you can
                  deliver a native app-like rich experience to users without
                  compromising on reliability, performance and connectivity
                  speed. Since these fast-loading applications do not require
                  app store approval, they enable businesses to push the app to
                  market quicker than before. With PWAs, companies can penetrate
                  the dense market segments glued to their mobile screens and
                  also benefit from its self-updating feature.
                </div>
                <div
                  style={{
                    marginTop: 20,
                    fontSize: 25,
                    fontWeight: 700
                  }}
                >
                  Creating Cutting Edge Progressive Apps
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: 30,
                fontWeight: 450
              }}
            >
              <div
                style={{
                  paddingLeft: 10,
                  paddingRight: 10
                }}
              >
                Companies of all sizes, whether large and small organizations,
                are jumping on Progressive Web Apps to create better user
                experiences. And we are here to help them do it! Whether you are
                an e-commerce platform, banking or insurance sector, or a
                media-information portal, Origami Studios can set up a cutting
                edge progressive web app for you that can expand your business
                reach and customer engagement.
              </div>
            </div>
            <RequestButton2 title="I want to create a progressive web app? " />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 50,
paddingRight:10,
            paddingLeft: 10
          }}
          className="progressive_main"
        >
          <div style={{ textAlign: responsive ? "" : "center" }}>
            <div style={{ fontSize: 25, fontWeight: 700 }}>
              Reasons to choose us
            </div>
            <div style={{ marginTop: 50, paddingLeft: 10, paddingRight: 10 }}>
              Our processes are streamlined to develop flaw-less progressive web
              applications. We achieve this by carrying out a series of multiple
              online and offline testing procedures, hereby ensuring that the
              final release surpasses client expectations. Here’s why we are
              different than others in the market:
              {/* <span style={{ color: "#E0E1E3" }}>
              ........................................................
            </span> */}
            </div>
          </div>
          <Grid container>
            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 70
              }}
              item
              xs={12}
              md={5}
            >
              <div>
                <img
                  width="100%"
                  height="auto"
                  src={require("../assets/ReasonsImg.png")}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={7}>
              <div
                style={{
                  display: responsive ? "" : "flex",
                  justifyContent: responsive ? "" : "center",
                  alignItems: responsive ? "" : "center",
                  flexDirection: responsive ? "" : "column"
                }}
              >
                <div
                  style={{
                    fontSize: responsive ? 17 : 14,
                    fontWeight: 400,
                    marginTop: 50
                  }}
                >
                  <div
                    style={{
                      marginTop: responsive ? 10 : 0,
                      display: "flex"
                    }}
                  >
                    <div>
                      <img src={require("../assets/right.png")} />
                    </div>
                    <div
                      style={{
                        marginTop: responsive ? 5 : 5
                      }}
                    >
                      Protect your app idea with signed NDA
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 10 : 0,
                      display: "flex"
                    }}
                  >
                    <div>
                      <img src={require("../assets/right.png")} />
                    </div>
                    <div
                      style={{
                        marginTop: responsive ? 5 : 5
                      }}
                    >
                      Free strategy call to refine app features, plan marketing
                      and monetize options
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 10 : 0,
                      display: "flex"
                    }}
                  >
                    <div>
                      <img src={require("../assets/right.png")} />
                    </div>
                    <div
                      style={{
                        marginTop: responsive ? 5 : 5
                      }}
                    >
                      Exceptional design and user experience
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 10 : 0,
                      display: "flex"
                    }}
                  >
                    <div>
                      <img src={require("../assets/right.png")} />
                    </div>
                    <div
                      style={{
                        marginTop: responsive ? 5 : 5
                      }}
                    >
                      Agile development to ensure you have the flexibility to
                      change requirements
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 10 : 0,
                      display: "flex"
                    }}
                  >
                    <div>
                      <img src={require("../assets/right.png")} />
                    </div>
                    <div
                      style={{
                        marginTop: responsive ? 5 : 5
                      }}
                    >
                      Effective use of our engineered boilerplate to reduce
                      development time and costs
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 10 : 0,
                      display: "flex"
                    }}
                  >
                    <div>
                      <img src={require("../assets/right.png")} />
                    </div>
                    <div
                      style={{
                        marginTop: responsive ? 5 : 5
                      }}
                    >
                      Seamless communication using Slack, Trello or any
                      preferred tool of your choice
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 10 : 0,
                      display: "flex"
                    }}
                  >
                    <div>
                      <img src={require("../assets/right.png")} />
                    </div>
                    <div
                      style={{
                        marginTop: responsive ? 5 : 5
                      }}
                    >
                      Robust test-driven app development (TDD)
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 10 : 0,
                      display: "flex"
                    }}
                  >
                    <div>
                      <img src={require("../assets/right.png")} />
                    </div>
                    <div
                      style={{
                        marginTop: responsive ? 5 : 5
                      }}
                    >
                      Manual and automated QA processes to test app across
                      dozens of devices
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 10 : 0,
                      display: "flex"
                    }}
                  >
                    <div>
                      <img src={require("../assets/right.png")} />
                    </div>

                    <div
                      style={{
                        marginTop: responsive ? 5 : 5
                      }}
                    >
                      Regular code commits to ensure transparency of code
                      handover
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                      display: "flex"
                    }}
                  >
                    <div>
                      <img src={require("../assets/right.png")} />
                    </div>
                    <div
                      style={{
                        marginTop: responsive ? 5 : 5
                      }}
                    >
                      Maintenance and after deployment support
                    </div>
                  </div>
                </div>
                <RequestButton2 title="Lets connect" />
              </div>
            </Grid>
          </Grid>
        </div>

        <NineCards
          t1="PROJECT REQUIREMENTS"
          t2="USE CASES"
          t3="PROJECT PLAN"
          t4="WIREFRAMES & PROTOTYPES"
          t5="DESIGN"
          t6="AGILE DEVELOPMENT"
          t7="TESTING & QA"
          t8="RELEASE"
          t9="MAINTENANCE & SUPPORT"
          d1="Project requirements are gathered and documented. Moreover, research is conducted to understand the project better."
          d2="Application features are written in the form of use cases and later shared with the client."
          d3="Division of the project development tasks into sprints."
          d4="Meanwhile, the teams work on producing wireframes."
          d5="Our design team adopts a cautious approach to design each screen before it goes into development."
          d6="The developers begin their magic and complete each sprint as planned."
          d7="Furthermore, we perform automated and manual quality checks to ensure the promised quality."
          d8="After thorough testing by our team and the client, we make the application live."
          d9="Post app launch, we deliver maintenance, quality support and enhancement services as required."
          img1={require("../assets/project.png")}
          img2={require("../assets/use.png")}
          img3={require("../assets/plan.png")}
          img4={require("../assets/Wireframing.png")}
          img5={require("../assets/design.png")}
          img6={require("../assets/agile.png")}
          img7={require("../assets/testing.png")}
          img8={require("../assets/release.png")}
          img9={require("../assets/maintenance.png")}
        />
        <div style={{ textAlign: responsive ? "" : "center" }}>
          <BuildAppSubmit />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default ProgressiveWebApps;
const dynamicCss = `
.progressive_app_header{
  background:${theme.bgBlue}
}
.progressive_app_header_text{
  color:${theme.textWhite}
}
.cross_development_main_card{
  background:${theme.bgLightGreen};
  color:${theme.textBlack}
}
.cross_development_main_card2{
  background:${theme.bgLightBlue};
  color:${theme.textBlack}
}
.progressive_main{
  background:${theme.bgGray};

}
.text_black{
 
  color:${theme.textBlack}
}

`;
