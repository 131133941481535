import React from "react";
import NavBarSec from "../Components/NavBar2";
import Footer from "../Components/Footer";
import { useLocation } from "react-router-dom";
import "./projectdemo.css";
import theme from '../theme/color.json'
const ProjectsDemos = () => {
  const location = useLocation();
  console.log("location", location);
  const demos = location.state;
  return (
    <>
      <style>{dynamicCss}</style>
      <NavBarSec />
      <div className="project_demo_container">
        {demos?.hide && <h2 className="titles">About</h2>}
        <div className="description">{demos?.projectDes}</div>
        <div className="description">{demos?.projectDes2}</div>
        {demos?.hide && <h2 className="titles">Tech Stack</h2>}
        <div className="description">{demos?.projectDes3}</div>
        <div className="demos_box">
          {demos.img.map((data) => {
            return (
              <div style={{ marginTop: 50 }}>
                <div>
                  <img
                    style={{ height: 550 }}
                    src={data?.image}
                    className="img-fluid"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProjectsDemos;
const dynamicCss = `
.titles{
  color:${theme.textBlack}
}
.description{
  color:${theme.textBlack}
}
`;