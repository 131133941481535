import React from "react";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import { Alert, Box, Grid, Modal } from "@mui/material";
import * as Yup from "yup";
import { useState } from "react";
import { useRef } from "react";
import emailjs from "emailjs-com";

const loginSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  email: Yup.string().email().required("An email is required"),
  message: Yup.string().required("This field is required"),
  phone: Yup.string().required("This field is required"),
});
function ModalButton() {
  const [hide, setHide] = useState(false);
  const formref = useRef();

  // const endPoint =
  //   "https://public.herotofu.com/v1/d25bfba0-6f4a-11ed-a377-655c67143cec";
  const handleSubmit = async (values) => {
    console.log(values, "myValues");
    const serviceId = "service_wfyb3vi";
    const templateId = "template_oopj4to";
    const userId = "o1sGgvXOrjeloB-wp";

    const response = await emailjs.send(
      serviceId,
      templateId,
      {
        name: values.name,
        email: values.email,
        phone: values.phone,
        message: values.message,
      },
      userId
    );

    if (response.status === 200) {
      console.log("Email sent successfully", response.status, response.text);
      console.log("Email sent successfully", response.status, response.text);
      setHide(!hide);
    } else {
      console.error("Failed to send email", response.status, response.text);
      console.error("Failed to send email", response.status, response.text);
    }
    // fetch(endPoint, {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(values),
    // }).then((res) => {
    //   console.log(res);
    //   setHide(!hide);
    // });
  };
  const handleSend = () => {
    setHide(!hide);
  };
  const [anchorE3, setAnchorE3] = React.useState(false);

  const handleOpenModal = () => {
    setAnchorE3(true);
  };
  const handleClose = () => {
    setAnchorE3(false);
  };

  const responsive = window.innerWidth > 500;

  return (
    <>
      {anchorE3 ? (
        <div
          style={{
            backgroundColor: "white",
            borderRadius: 20,
            width: 340,
            height: 520,
            position: "fixed",
            right: 0,
            marginRight: 20,
            marginLeft: 20,
            bottom: 100,
            cursor: "pointer",
          }}
        >
          <div
            style={{
              backgroundColor: "#E93F33",
              height: 75,
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 20,
            }}
          >
            <p
              style={{
                fontSize: 16,
                color: "white",
              }}
            >
              Please fill out the form below and we will get
            </p>
            <p style={{ fontSize: 16, color: "white" }}>
              back to you as soon as possible.
            </p>
          </div>

          {hide ? (
            <>
              <div
                style={{
                  height: "80%",
                  // backgroundColor: "blue",
                  boxShadow: " 0px 15px 15px  #8490ff",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                Your message was sent successfully!
                <button
                  type="submit"
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#D22931",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 5,
                    padding: 12,
                    marginTop: 10,
                    color: "white",
                    textTransform: "none",
                    borderWidth: 0,
                    fontSize: 18,
                  }}
                >
                  <div onClick={handleSend}>Send Again</div>
                </button>
              </div>

              <div
                style={{
                  backgroundColor: "white",
                  marginTop: -30,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  height: 40,
                  width: "100%",
                  borderBottomLeftRadius: 5,
                  borderBottomRightRadius: 5,
                }}
              >
                <img
                  width={20}
                  height={20}
                  src="https://embed.tawk.to/_s/v4/assets/images/Tawky_16x16.svg"
                />
                <p style={{ color: "gray", fontSize: 13 }}>
                  Add free live chat to your site
                </p>
              </div>
            </>
          ) : (
            <Grid
              style={{
                backgroundColor: "whitesmoke",
                boxShadow: "1px 2px 9px #8748D7",
                width: "100%",
              }}
              item
              md={5}
            >
              <Formik
                innerRef={formref}
                initialValues={{
                  name: "",
                  email: "",
                  message: "",
                  phone: "",
                }}
                validationSchema={loginSchema}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                <Form>
                  <div
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      marginBottom: 50,
                      backgroundColor: "white",
                      marginTop: -10,
                      paddingTop: 3,
                      paddingBottom: 3,
                      paddingLeft: 10,
                      paddingRight: 10,
                      borderBottomLeftRadius: 10,
                      borderBottomRightRadius: 10,
                    }}
                  >
                    <Field
                      component={TextField}
                      margin="normal"
                      name="name"
                      label="Name"
                      type="name"
                      id="name"
                      fullWidth
                      disabled={false}
                      size="small"
                    />
                    <div style={{}}>
                      <Field
                        component={TextField}
                        margin="normal"
                        fullWidth
                        required
                        id="email"
                        label="Email"
                        name="email"
                        disabled={false}
                        size="small"
                      />

                      <Field
                        component={TextField}
                        placeholder="Tell us about your product and business challenge."
                        rows="3"
                        margin="normal"
                        name="message"
                        label="Message"
                        type="message"
                        multiline
                        fullWidth
                        disabled={false}
                      />
                    </div>
                    <Field
                      style={{}}
                      component={TextField}
                      margin="normal"
                      fullWidth
                      name="phone"
                      label="Phone"
                      type="phone"
                      id="phone"
                      disabled={false}
                      size="small"
                    />
                    <button
                      type="submit"
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#D22931",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 5,
                        padding: 12,
                        color: "white",
                        marginTop: 10,
                        marginBottom: 20,
                        textTransform: "none",
                        borderWidth: 0,
                        fontSize: 18,
                        width: "100%",
                      }}
                    >
                      <div>Submit</div>
                    </button>
                  </div>
                </Form>
              </Formik>
              <div
                style={{
                  backgroundColor: "white",
                  marginTop: -30,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  height: 40,
                  width: "100%",
                  borderBottomLeftRadius: 5,
                  borderBottomRightRadius: 5,
                }}
              >
                <img
                  width={20}
                  height={20}
                  src="https://embed.tawk.to/_s/v4/assets/images/Tawky_16x16.svg"
                />
                <p style={{ color: "gray", fontSize: 13 }}>
                  Add free live chat to your site
                </p>
              </div>
            </Grid>
          )}
        </div>
      ) : (
        <></>
      )}
      {anchorE3 ? (
        <div
          onClick={handleClose}
          style={{
            backgroundColor: "red",
            borderRadius: 40,
            position: "fixed",
            right: 0,
            marginRight: 20,
            bottom: 10,
            width: 55,
            height: 55,
            cursor: "pointer",
          }}
        >
          <img
            width={30}
            height={30}
            style={{
              justifyContent: "center",
              alignItems: "center",
              margin: 13,
            }}
            src={require("../assets/cross.png")}
          />
        </div>
      ) : (
        <div
          onClick={handleOpenModal}
          style={{
            backgroundColor: "red",
            borderRadius: 40,
            position: "fixed",
            right: 0,
            marginRight: 20,
            bottom: 10,
            width: 55,
            height: 55,
            cursor: "pointer",
          }}
        >
          <img
            width={30}
            height={30}
            style={{
              justifyContent: "center",
              alignItems: "center",
              margin: 13,
            }}
            src={require("../assets/message.png")}
          />
        </div>
      )}
    </>
  );
}

export default ModalButton;
