import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import { Grid } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ReactPaginate from "react-paginate";
import NavBarSec from "../Components/NavBar2";
import {
  blogCardData,
  topBlogCard,
} from "../Components/BlogDetails/blogCardData";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";

const blogData = [];
const Blog = () => {
  const navigation = useNavigate();
  const [pageCount, setPageCount] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  let itemsPerPage = 5;

  useEffect(() => {
    console.log(blogCardData, "dataProduct");
    const endOffset = itemOffset + itemsPerPage;

    if (blogCardData?.length >= 1) {
      setCurrentItems(blogCardData?.slice(itemOffset, endOffset));
      setPageCount(Math?.ceil(blogCardData.length / itemsPerPage));
    }
  }, [itemOffset, itemsPerPage, blogCardData?.length, blogCardData]);
  const handlePageClick = (event) => {
    const newOffSet = (event.selected * itemsPerPage) % blogCardData.length;
    setItemOffset(newOffSet);
  };
  const responsive = window.innerWidth > 500;
  return (
    <div>
      <NavBarSec />
      <div style={{ textAlign: responsive ? "" : "Center" }}>
        <div className="blog_background"></div>
        <div
          style={{
            position: "absolute",
            top: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <div style={{ color: "white", fontSize: 35, fontWeight: 600 }}>
            Empowering Through Knowledge
          </div>
        </div>
        <div>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: -190,
            }}
            container
            md={12}
            xs={12}
          >
            {topBlogCard.map((i) => (
              <Grid
                onClick={() => navigation("/BlogDetails", { state: i })}
                style={{ marginRight: 20, marginLeft: 20,cursor:'pointer' }}
                item
                xs={12}
                md={3}
              >
                <div
                  style={{
                    border: "1px solid lightgray",
                    marginBottom: 20,
                    borderRadius: 10,
                    borderTopLeftRadius: 11,
                    borderTopRightRadius: 11,
                  }}
                >
                  <div>
                    <img
                      style={{
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                      width="100%"
                      height={200}
                      src={i.blogImg}
                    />
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <div
                      style={{
                        marginLeft: 20,
                        fontSize: 24,
                        fontWeight: 700,
                        color: "#333333",
                      }}
                    >
                      {i.mainHead}
                    </div>
                    <div
                      style={{
                        marginLeft: 20,
                        color: "#1D0101",
                        fontSize: 16,
                        paddingBottom: 20,
                        marginRight: 25,
                      }}
                    >
                      {i.headDesc}
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      <div>
        <Grid
          md={12}
          container
          style={{
            display: responsive ? "flex" : "",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
            paddingLeft: responsive ? "" : 20,
            paddingRight: responsive ? "" : 20,
            cursor:'pointer'
          }}
        >
          {currentItems.map((i, index) => (
            <Grid
              onClick={() => navigation("/BlogDetails", { state: i })}
              item
              md={9.5}
              style={{
                display: responsive ? "flex" : "",
                boxShadow: "1px 2px 9px lightgray",
                borderRadius: 10,
                marginBottom: 25,
                height: responsive ? 280 : 650,
              }}
            >
              <Grid item xs={12} sm={4} md={5} key={index}>
                <img
                  style={{
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 10,
                  }}
                  width="100%"
                  height="100%"
                  src={i.blogImg}
                />
              </Grid>
              <Grid md={7} xs={12}>
                <div
                  style={{
                    marginRight: 20,
                    marginLeft: 20,
                    marginTop: 10,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#F8FAFF",
                      padding: 5,
                      width: 60,
                      display: "flex",
                      justifyContent: "center",
                      fontSize: 10,
                      borderRadius: 15,
                    }}
                  >
                    {i.type}
                  </div>
                  <div style={{ fontSize: 22, fontWeight: 700 }}>{i.title}</div>
                  <div style={{ marginTop: 20, fontWeight: 600, fontSize: 15 }}>
                    {i.maindesc}
                  </div>
                </div>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <ReactPaginate
          nextLabel={<KeyboardArrowRightIcon />}
          previousLabel={<KeyboardArrowLeftIcon />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          breakLabel="..."
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num2"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="active"
        />
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
