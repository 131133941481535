import React from "react";
import Modal from "@mui/material/Modal";
import Carousel from "react-material-ui-carousel";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: 400,
  width: "80vw",
  backgroundColor: "white",
  zIndex: 1,
  boxShadow: "1px 2px 9px lightgray",
  bottom: 100,
  borderRadius: 3,
  outline: "none",
};

const ProjectDetailModal = ({
  open,
  handleClose,
  allData,
  allText,
  allText2,
  allText3,
  docId,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <div style={{ outline: "none" }}>
        <Box
          className="active"
          sx={{
            left: "50%",
            height: 450,
            width:
              docId === "1" || docId === "2" || docId === "3" ? "50vw" : "80vw",
            backgroundColor: "white",
            zIndex: 1,
            boxShadow: "1px 2px 9px lightgray",
            borderRadius: 3,
            borderWidth: 0,
            outline: "none",
            display: { md: "flex", xs: "none" },
            transform:
              docId === "1" || docId === "2" || docId === "3"
                ? "translate(45%, 25%)"
                : "translate(13%, 25%)",
          }}
        >
          <div
            onClick={handleClose}
            style={{
              position: "absolute",
              left: 10,
              outline: "none",
              cursor: "pointer",
            }}
          >
            <CloseIcon style={{ fontSize: 30, marginTop: 10 }} />
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                width:
                  docId === "1" || docId === "2" || docId === "3"
                    ? "170%"
                    : "40%",
                height: 450,
                backgroundColor: "#E1DFDE",
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
              }}
            >
              <div
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 30,
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: 20,
                  fontFamily: "Roboto",
                }}
              >
                About
              </div>
              <div
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 20,
                  textAlign: "center",
                  fontFamily: "Droid Sans",
                }}
              >
                {allText}
              </div>
              <div
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 10,
                  textAlign: "center",
                  fontFamily: "Droid Sans",
                }}
              >
                {allText2}
              </div>
              <div
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 30,
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: 20,
                  fontFamily: "Roboto",
                }}
              >
                Tech Stack
              </div>
              <div
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 10,
                  textAlign: "center",
                  fontFamily: "Droid Sans",
                }}
              >
                {allText3}
              </div>
            </div>
            <div style={{ width: "100%", height: "100%" }}>
              <Carousel autoPlay={true}>
                {allData &&
                  allData.map((item, i) => (
                    <img
                      style={{
                        width: "100%",
                        height: 450,
                        borderTopRightRadius: 10,
                        borderBottomRightRadius: 10,
                      }}
                      // className="CarouselImage"
                      key={item.img}
                      src={item.img}
                      alt={`${i} Slide`}
                    />
                  ))}
              </Carousel>
            </div>
            <div>
              <img src="" />
            </div>
          </div>
        </Box>
        <Box
          className="active"
          sx={{
            position: "absolute",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: 500,
            width: "90vw",
            backgroundColor: "white",
            zIndex: 1,
            boxShadow: "1px 2px 9px lightgray",
            bottom: 100,
            borderRadius: 3,
            outline: "none",
            display: { md: "none", xs: "flex" },
            justifyContent: "center",
            marginTop: 15,
          }}
        >
          <div style={{}}>
            <div
              style={{
                width: "100%",
                backgroundColor: "#E1DFDE",
              }}
            >
              <div
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 30,
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: 20,
                  fontFamily: "Roboto",
                }}
              >
                About
              </div>
              <div
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 20,
                  textAlign: "center",
                  fontFamily: "Droid Sans",
                }}
              >
                {allText}
              </div>
              <div
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 10,
                  textAlign: "center",
                  fontFamily: "Droid Sans",
                }}
              >
                {allText2}
              </div>
              <div
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 30,
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: 20,
                  fontFamily: "Roboto",
                }}
              >
                Tech Stack
              </div>
              <div
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 10,
                  textAlign: "center",
                  fontFamily: "Droid Sans",
                }}
              >
                {allText3}
              </div>
            </div>
            <div style={{ width: "100%", height: "100%" }}>
              <Carousel autoPlay={true}>
                {allData &&
                  allData.map((item, i) => (
                    <img
                      style={{
                        width: "100%",
                        height:
                          docId === "1" || docId === "2" || docId === "3"
                            ? 500
                            : 300,
                      }}
                      key={item.img}
                      src={item.img}
                      alt={`${i} Slide`}
                    />
                  ))}
              </Carousel>
            </div>
            <div>
              <img src="" />
            </div>
          </div>
        </Box>
      </div>
    </Modal>
  );
};

export default ProjectDetailModal;
