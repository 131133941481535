import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import BuildAppSubmit from "./BuildAppSubmit";
import MetaData from "./MetaData";
import NineCards from "./NineCards";
import RequestButton from "./RequestButton";
import NavBarSec from "../Components/NavBar2";
import RequestButton2 from "./RequestButton2";

function IotDevelopment() {
  const responsive = window.innerWidth > 500;
  return (
    <>
      <NavBarSec />
      <div style={{ textAlign: responsive ? "" : "center" }}>
        <MetaData
          title={
            "IoT Solutions - Revolutionary Step for Revolutionary Businesses"
          }
        />
        <Grid
          md={12}
          style={{
            background: "#12209F",
            minHeight: 650,
            display: "flex",
            justifyContent: "center"
          }}
          container
        >
          <Grid item xs={12} md={6}>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: responsive ? "" : 10
              }}
            >
              <div style={{ color: "white" }}>
                <div
                  style={{
                    fontSize: responsive ? 40 : 25,
                    fontWeight: 700,
                    marginRight: responsive ? 0 : 10,
                    marginLeft: responsive ? 0 : 10
                  }}
                >
                  IoT Solutions - Revolutionary Step for Revolutionary
                  Businesses
                </div>
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    marginTop: 10,
                    marginRight: responsive ? 0 : 10,
                    marginLeft: responsive ? 0 : 10
                  }}
                >
                  Bitsbay implementation of IoT has helped businesses improve
                  day to day operations adding to their efficiency.
                </div>
                <RequestButton />
              </div>
            </div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div style={{ marginTop: 70 }}>
              <img
                width="100%"
                height="100%"
                src={require("../assets/IOT.png")}
              />
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            paddingTop: 50,
            paddingLeft: 10,
            paddingRight: 10
          }}
        >
          <div>
            <div
              style={{
                fontSize: 25,
                fontWeight: 630
              }}
            >
              TOP NOTCH
            </div>
            <div
              style={{
                fontSize: 25,
                fontWeight: 700
              }}
            >
              IoT Development Services
            </div>
            <div
              style={{
                marginTop: 30,
                fontWeight: 450,
                paddingLeft: 10,
                paddingRight: 10
              }}
            >
              The IoT technology has added more value to our everyday lives and
              businesses than we can imagine. By minimizing the human-human and
              human-machine interaction, it indeed has opened a whole new
              multiverse of innovation. Connected devices like smart TV, smart
              watches, smart speakers, smart air conditioning and smart
              thermostats, etc are ruling our lives making them easier every
              day. When it comes to choosing the top IoT development services,
              we are here to make your choice easier.
              <div
                style={{
                  marginTop: 30,
                  fontSize: 25,
                  fontWeight: 700
                }}
              >
                Keeping Up With IoT
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: 30,
              fontWeight: 450,
              paddingLeft: 10,
              paddingRight: 10
            }}
          >
            There are not many companies in the market with the required
            experience and skill to provide top-notch IoT solutions.
            Fortunately, Bitsbay is an IoT app company development deliver that
            can advanced IoT development services helping you transform your
            business into a futuristic venture way above your competitors.
            <div
              style={{
                marginTop: 30,
                fontWeight: 450,
                paddingLeft: 10,
                paddingRight: 10
              }}
            >
              <div>
                Being passionate about technology and thriving on innovation, we
                love to breathe life into your business and application ideas
                with IoT technology. Over the years are IoT architects have
                mastered their command over this tech. When it comes to IoT
                development services we offer connectivity/MQM Platforms, IAAS
                backends, hardware specific Software Platforms,
                Consumer/Enterprise Software, Google’s Brillo/Weave, Microsoft
                Azure, Calvin by Ericsson and more.
              </div>
            </div>
          </div>

          <RequestButton2 title="I need IoT services. Let's discuss" />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 50,
          background: "#E0E1E3",
          paddingLeft: 10,
          paddingRight:10
        }}
      >
        <div style={{ textAlign: responsive ? "" : "center" }}>
          <div style={{ fontSize: 25, fontWeight: 700 }}>
            Reasons to choose us
          </div>
          <div style={{ marginTop: 30, paddingLeft: 10, paddingRight: 10 }}>
            Our client-oriented services make us an ideal fit for your IoT
            technology implementation. Here’s a list why you should let us be
            your technology partners:
          </div>
        </div>
        <Grid container>
          <Grid container>
            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 70
              }}
              item
              xs={12}
              md={6}
            >
              <div>
                <img
                  width="100%"
                  height="auto"
                  src={require("../assets/ReasonsImg.png")}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={5}>
              <div
                style={{
                  display: responsive ? "" : "flex",
                  justifyContent: responsive ? "" : "center",
                  alignItems: responsive ? "" : "center",
                  flexDirection: responsive ? "" : "column"
                }}
              >
                <div style={{ fontSize: 17, fontWeight: 600, marginTop: 50 }}>
                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <img
                      style={{ marginRight: 5 }}
                      src={require("../assets/right.png")}
                    />
                    <div
                      style={{
                        paddingLeft: 20,
                        fontWeight: 400,
                        color: "#262e32"
                      }}
                    >
                      Your idea is safe with us. A signed NDA will ensure the
                      confidentiality of your idea.
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <img
                      style={{ marginRight: 5 }}
                      src={require("../assets/right.png")}
                    />
                    <div
                      style={{
                        paddingLeft: 20,
                        fontWeight: 400,
                        color: "#262e32"
                      }}
                    >
                      We set up a strategy call to discuss features, pricing
                      plans and marketing options
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <img
                      style={{ marginRight: 5 }}
                      src={require("../assets/right.png")}
                    />
                    <div
                      style={{
                        paddingLeft: 20,
                        fontWeight: 400,
                        color: "#262e32"
                      }}
                    >
                      Later on, the team creates sprint plans and sets upon a
                      project management tool
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <img
                      style={{ marginRight: 5 }}
                      src={require("../assets/right.png")}
                    />
                    <div
                      style={{
                        paddingLeft: 20,
                        fontWeight: 400,
                        color: "#262e32"
                      }}
                    >
                      For a flexible approach, our choice is the Agile
                      development process
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <img
                      style={{ marginRight: 5 }}
                      src={require("../assets/right.png")}
                    />
                    <div
                      style={{
                        paddingLeft: 20,
                        fontWeight: 400,
                        color: "#262e32"
                      }}
                    >
                      Through Skype, Slack or Trello we maintain effective
                      communication
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <img
                      style={{ marginRight: 5 }}
                      src={require("../assets/right.png")}
                    />
                    <div
                      style={{
                        paddingLeft: 20,
                        fontWeight: 400,
                        color: "#262e32"
                      }}
                    >
                      Team Bitsbay also conducts automated and manual quality
                      assurance tests
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <img
                      style={{ marginRight: 5 }}
                      src={require("../assets/right.png")}
                    />
                    <div
                      style={{
                        paddingLeft: 20,
                        fontWeight: 400,
                        color: "#262e32"
                      }}
                    >
                      Code commits on a regular basis to ensure transparency
                    </div>
                  </div>
                </div>
                <RequestButton2 title="Lets connect" />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <NineCards
        t1="PROJECT REQUIREMENTS
        "
        t2="USE CASES
        "
        t3="PROJECT PLAN
        "
        t4="WIREFRAMES & PROTOTYPES
        "
        t5="DESIGN
        "
        t6="AGILE DEVELOPMENT
        "
        t7="TESTING & QA
        "
        t8="RELEASE
        "
        t9="MAINTENANCE &
        SUPPORT"
        d1="A dedicated team member gathers project requirements from the client.

        "
        d2="The requirements are then used to draft use cases of the application.

        "
        d3="Project development is divided into sprints and managed via PM tool.

        "
        d4="App wireframes are developed for the client to get an idea of the final product.

        "
        d5="The wireframes are used as reference to create application designs.

        "
        d6="Project development is done sprint wise with regular code reviews and testing.

        "
        d7="After completion, QA testing is conducted to ensure there are no bugs.

        "
        d8="The product is then uploaded to live and made available to the target audience.

        "
        d9="Post app launch, we deliver maintenance, quality support and enhancement services as required.

        "
        img1={require("../assets/project.png")}
        img2={require("../assets/use.png")}
        img3={require("../assets/plan.png")}
        img4={require("../assets/Wireframing.png")}
        img5={require("../assets/design.png")}
        img6={require("../assets/agile.png")}
        img7={require("../assets/testing.png")}
        img8={require("../assets/release.png")}
        img9={require("../assets/maintenance.png")}
      />
      <div style={{ textAlign: responsive ? "" : "center" }}>
        <BuildAppSubmit />
        <Footer />
      </div>
    </>
  );
}

export default IotDevelopment;
